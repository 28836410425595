<template>
  <div class="product-box">
    <div class="head-box animated fadeIn">
      <div class="content">
        <div class="title animated fadeInDown">医务一体化平台</div>
        <div class="tip animated fadeInUp">助力医院评审，满足医务部门日常工作及精细化管理需求</div>
      </div>
    </div>

    <div class="box1">
      <img class="img animated zoomIn" src="@/assets/img/product/2.png" />
    </div>
    <div v-for="(val, index) in initData" :key="val.id" :id="val.id">
      <div class="box2" v-if="isEven(index)">
        <div class="text-box animated" :class="{ bounceInLeft: val.show }" :style="{ visibility: val.show ? 'visible':'hidden' }">
          <div class="title">{{val.title}}</div>
          <div class="desc">{{val.desc}}</div>
        </div>
        <div class="img-box animated" :class="{ bounceInRight: val.show }" :style="{ visibility: val.show ? 'visible':'hidden' }">
          <img class="img" :style="{width: val.width}" :src="val.img" />
        </div>
      </div>
      <div class="box2" v-else>
        <div class="img-box animated" :class="{ bounceInLeft: val.show }" :style="{ visibility: val.show ? 'visible':'hidden' }">
          <img class="img" :style="{width: val.width}" :src="val.img" />
        </div>
        <div class="text-box animated" :class="{ bounceInRight: val.show }" :style=" { visibility: val.show ? 'visible' :'hidden' }">
          <div class="title">{{val.title}}</div>
          <div class="desc">{{val.desc}}</div>
        </div>
      </div>
    </div>
    <div class="box1" id="animatedBox8">
      <div class="name animated" :class="{ flipInX: show8 }" :style="{ visibility: show8 ? 'visible':'hidden' }">更多功能模块，全方位提高医务人员工作效率</div>
      <img class="img animated" :class="{ flipInX: show8 }" :style="{ visibility: show8 ? 'visible':'hidden', width: '600px' }" src="@/assets/img/product/10.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initData: [
        {
          id: 'animatedBox1',
          show: false,
          title: '满足档案管理需求，提升医务管理工作效率',
          desc: '支持医师自行完善个人档案资料，包括证件、证书等电子档案上传等供医务管理员审核确认。保障了医生信息变更的及时性，避免出现手工处理周期长、容易出差错等问题。',
          img: require('@/assets/img/product/3.png'),
          width: '90%',
        },
        {
          id: 'animatedBox2',
          show: false,
          title: '实现医师各类权限全生命周期闭环管理',
          desc: '与医院相关系统对接，同步医师权限实现医师权限查询、各类权限申请权限变更、手术分级管理、麻醉分级管理、抗菌药物分级管理等，为授权审批提供更准确，更客观的参考依据。',
          img: require('@/assets/img/product/4.png'),
          width: '80%',
        },
        {
          id: 'animatedBox3',
          show: false,
          title: '新技术新项目闭环管理，实现全过程追踪',
          desc: '全面评估的新技术新项目申请、自定义审批并分阶段(开展、延期、终止、转常规)管理，根据医院现有规则定期对开展中的新技术做阶段性评价和临床应用的登记，转常规后技术可供医师授权申请，生成统计报表。',
          img: require('@/assets/img/product/5.png'),
          width: '100%',
        },
        {
          id: 'animatedBox4',
          show: false,
          title: '满足医生、医技、实习生排班及轮科需求',
          desc: '涵盖班次设置、排班管理、加班登记、医师工作量统计、全院医师工作量汇总统计、统计报表输出等内容。操作简单，可通过拖拽、引用等方式实现医务人员的快速排班。',
          img: require('@/assets/img/product/6.png'),
          width: '60%',
        },
        {
          id: 'animatedBox5',
          show: false,
          title: '灵活定义模板，为质量改进提供数据支撑',
          desc: '可通过灵活自定义模块例如医疗服务规范、业务能力、医风医德等模块登记的内容，协助进行医师考核。',
          img: require('@/assets/img/product/7.png'),
          width: '60%',
        },
        {
          id: 'animatedBox6',
          show: false,
          title: '全院医师动态监测，科室资源调配更合理',
          desc: '全院医师动态管理、个人管理、去向查询和医师出勤统计等，掌握全院医师在岗、请假、义诊、进修、住院开单、接诊以及各科室医生等级、职称、学历、出勤比例等，规避风险存在。',
          img: require('@/assets/img/product/8.png'),
          width: '40%',
        },
        {
          id: 'animatedBox7',
          show: false,
          title: '医疗风险管控，及时发现问题化解矛盾',
          desc: '依据国务院颁发的《医疗事故处理条例》，提供涵盖投诉登记、投诉分发、科室反馈、医院调查、调解处理等流程工作支持，有效帮助加强医院和患者之间的沟通。',
          img: require('@/assets/img/product/9.png'),
          width: '40%',
        },
      ],
      show8: false,
    }
  },
  created() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // 可选，平滑滚动效果
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.initData.forEach(v => {
        let element = document.getElementById(v.id)
        // 获取元素的位置和尺寸
        let rect = element.getBoundingClientRect();
        // 计算元素和浏览器窗口底部之间的距离
        let distance = window.innerHeight - rect.bottom;
        if (distance > -300) {
          v.show = true
        }
      })

      let element8 = document.getElementById('animatedBox8')
      let rect8 = element8.getBoundingClientRect();
      let distance8 = window.innerHeight - rect8.bottom;
      if (distance8 > -300) {
        this.show8 = true
      }
    },
    isEven(number) {
      return number % 2 === 0;
    },
  }
}
</script>

<style lang="less" scoped>
.product-box {
  height: 100%;
  padding-top: 68px;
  color: #000;
  font-weight: 500;
  .head-box {
    position: relative;
    width: 100%;
    height: 500px;
    background: url("~@/assets/img/product/1.png") no-repeat center/cover;
    margin-bottom: 100px;
    .content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      padding: 100px 12% 40px;
      font-family: "楷体", "楷体_GB2312";
    }
    .title {
      font-size: 100px;
      font-weight: 500;
      text-shadow: 0 0 1px currentColor, -1px -1px 1px #000, 0 -1px 1px #000,
        1px -1px 1px #000, 1px 0 1px #000, 1px 1px 1px #000, 0 1px 1px #000,
        -1px 1px 1px #000, -1px 0 1px #000;
    }
    .tip {
      margin-top: 32px;
      font-size: 40px;
    }
  }
  .box1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
    .name {
      margin-bottom: 20px;
      font-size: 40px;
      color: #333;
      font-family: "宋体";
      font-weight: 900;
    }
    .img {
      width: 50%;
    }
  }
  .box2 {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 100px;
    margin-bottom: 80px;
    .img-box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #39919d;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 16px;
        font-weight: 500;
        color: #333;
      }
    }
  }
}
</style>
